<template>
  <div class="detail_container">
    <div class="header">
      <div class="info">

        <div>科室：{{clinicPaidRecordsDetail.deptName}}</div>
        <div>医生：{{clinicPaidRecordsDetail.doctorName}}</div>
        <div>就诊人：{{clinicPaidRecordsDetail.patName}}</div>
        <div>就诊时间：{{clinicPaidRecordsDetail.diagnTime}}</div>
        <div>门诊费：{{clinicPaidRecordsDetail.totalAmout}}元</div>
        <div class="barCode">
          <img :src="'data:image/png;base64,'+ clinicPaidRecordsDetail.barCode">
        </div>
      </div>
      <div class="position">
        导诊信息：
        <span style="color:#1989fa">{{clinicPaidRecordsDetail.guideListInfo}}</span>
      </div>
    </div>
    <div class="detail">
      <div class="title">收费明细</div>
      <div class="list"
           v-for="(item,index) in clinicPaidRecordsDetail.item"
           :key="index">
        <van-cell :title="item.itemTotalFee+'元'"
                  :value="'x'+item.itemNumber+'/'+item.itemUnit"
                  :label="item.itemName" />
      </div>
    </div>

    <div class="qrCode">
      <img :src="'data:image/png;base64,'+ clinicPaidRecordsDetail.invoiceQRCode">
    </div>
    <div class="tip">生成电子发票十分钟左右后可凭此二维码到自助机内扫描打印电子发票</div>
  </div>
</template>

<script>
import { jsonPost, formPost } from "@/baseAPI";
import qs from "qs";
export default {
  name: "Detail",
  data () {
    return {
      patCardNo: "",
      patCardType: "",
      agtOrdNum: "",
      orderNum: "",
      idCard: "",
      clinicPaidRecordsDetail: {}
    };
  },
  created () {
    this.patCardNo = this.$route.query.patCardNo;
    this.patCardType = this.$route.query.patCardType;
    this.agtOrdNum = this.$route.query.agtOrdNum;
    this.orderNum = this.$route.query.orderNum;
    this.idCard = this.$route.query.idCard;
    this.getClinicPaidRecordsDetail();
  },
  methods: {
    //获取门诊已缴费详情
    async getClinicPaidRecordsDetail () {
      this.$toast.loading({
        duration: 0,
        message: "加载中",
        forbidClick: true
      });
      const { data: res } = await jsonPost(
        "/wxapp/outpatient/getClinicPaidRecordsDetail",
        {
          openId: window.localStorage.getItem("openId"),
          agtOrdNum: this.agtOrdNum,
          orderNum: this.orderNum,
          patCardType: this.patCardType,
          patCardNo: this.patCardNo,
          idCard: this.idCard
        }
      );
      this.$toast.clear();
      if (res.code === 0) {
        this.clinicPaidRecordsDetail = res.data;
      } else {
        this.$toast.fail(res.msg);
      }
    }
  }
};
</script>

<style  scope>
.detail_container {
  padding-bottom: 60px;
}
.detail_container .header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: white;
}
.detail_container .header .info {
  font-size: 40px;
  margin-left: 30px;
  border-bottom: 1px solid skyblue;
  padding: 20px 0;
}
.detail_container .header .info div {
  padding: 6px 0;
}
.detail_container .header .position {
  padding: 10px 30px;
  font-size: 40px;
  border-bottom: 2px solid skyblue;
  line-height: 60px;
}
.detail_container .detail .title {
  padding: 20px 0;
  padding-left: 30px;
  font-size: 40px;
  border-bottom: 1px solid #f5f5f5;
}
.detail_container .van-cell__title,
.detail_container .van-cell__value,
.detail_container .van-cell__label {
  font-size: 40px !important;
}
.detail_container .van-cell__label {
  line-height: 70px;
}
/* .detail_container .barCode {
  margin: 0 auto;
  width: 500px;
  height: 400px;
} */
.detail_container .barCode img {
  width: 100%;
  height: 100%;
}
.detail_container .qrCode {
  margin: 0 auto;
  width: 500px;
  height: 500px;
}
.detail_container .qrCode img {
  width: 100%;
  height: 100%;
}
.detail_container .tip {
  font-size: 40px;
  padding: 0 30px;
  margin-bottom: 30px;
}
</style>